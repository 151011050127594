.heroUnit {
    height: 66.67vw !important;
    width: 100vw !important;
    color: #FFF;
    padding: 0 !important;
}

.gradient {
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(-45deg, rgba(183,0,121,0.40) 0%, rgba(182,2,121,0.40) 1%, rgba(153,52,135,0.40) 31%, rgba(126,71,147,0.40) 51%, rgba(104,130,187,0.40) 75%, rgba(110,158,207,0.40) 99%, rgba(110,158,207,0.40) 100%), url("/img/021-min.jpg");
    background: -webkit-linear-gradient(-45deg, rgba(183,0,121,0.40) 0%,rgba(182,2,121,0.40) 1%,rgba(153,52,135,0.40) 31%,rgba(126,71,147,0.40) 51%,rgba(104,130,187,0.40) 75%,rgba(110,158,207,0.40) 99%,rgba(110,158,207,0.40) 100%), url("/img/021-min.jpg");
    background: linear-gradient(135deg, rgba(183,0,121,0.40) 0%,rgba(182,2,121,0.40) 1%,rgba(153,52,135,0.40) 31%,rgba(126,71,147,0.40) 51%,rgba(104,130,187,0.40) 75%,rgba(110,158,207,0.40) 99%,rgba(110,158,207,0.40) 100%), url("/img/021-min.jpg");
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40b70079', endColorstr='#406e9ecf',GradientType=1 );
    background-repeat: no-repeat;
    object-fit: cover;
    padding: 2rem;
}

@media all {
    .gradient h1 {
        font-size: 1.1rem;
    }
    .gradient h4 {
        font-size: .6rem;
    }
}

@media (min-width: 550px) {
    .gradient h1 {
        font-size: 1.5rem;
    }
    .gradient h4 {
        font-size: 1rem;
    }
}

@media (min-width: 735px) {
    .gradient h1 {
        font-size: 3rem;
    }
    .gradient h4 {
        font-size: 1.7rem;
    }
}

@media screen and (min-aspect-ratio: 2/3) {
    .gradient {
        background-size: auto 100%;
    }
}

@media screen and (max-aspect-ratio: 2/3) {
    .gradient {
        background-size: 100% auto;
    }
}