#offer {
    color: #FFF;
    font-weight: light;
    height: auto;
    background: rgb(192,0,122);
    background: -moz-radial-gradient(to bottom right, ellipse cover,  rgba(192,0,122,1) 0%, rgba(8,1,24,1) 70%);
    background: -webkit-radial-gradient(to bottom right, ellipse cover,  rgba(192,0,122,1) 0%,rgba(8,1,24,1) 70%);
    background: radial-gradient(ellipse at bottom right,  rgba(192,0,122,1) 0%,rgba(8,1,24,1) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0007a', endColorstr='#080118',GradientType=1 );
    color: #FFF;
}
#offer .box_line {
    position: relative;
}

#offer .box_line::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background: #FFF;
}
@media (min-width: 1199.98px) {
    #offer .mobile_online.box_line::after {
        height: 0;
    }
}

.arrowRight {
    width: 0;
    height: 0;
    border-top: 5em solid transparent;
    border-bottom: 5em solid transparent;
    border-left: 5em solid rgba(207,3,136,1);
    z-index: 3;
    float:left;
    position: relative;
}

.offerNavbarBody {
    height: 100%;
    width:33%;
    float:left;
    z-index: 3;
    position: relative;
    background: rgb(194,37,164);
    background: -moz-linear-gradient(45deg, rgba(194,37,164,1) 0%, rgba(207,3,136,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(194,37,164,1) 0%,rgba(207,3,136,1) 100%);
    background: linear-gradient(45deg, rgba(194,37,164,1) 0%,rgba(207,3,136,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c225a4', endColorstr='#cf0388',GradientType=1 );
}

.offerNavbarBody:nth-child(3) {
    width: 34%;
}

.offerNavbarBody:nth-of-type(3) {
    margin-left: -5em;
    z-index: 2;
    position: relative;
    background: #765eb2;

}

.offerNavbarBody:nth-of-type(1)::before {
    content: 'TRIATHLON';
    font-size: 1.7em;
    top: 40%;
    position: relative;

}

.offerNavbarBody:nth-of-type(1):hover,
.offerNavbarBody:nth-of-type(3):hover,
.offerNavbarBody:nth-of-type(5):hover {
    -webkit-animation-duration: .5s; /* Safari 4.0 - 8.0 */
    animation-name: intro;
    animation-duration: .5s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes intro {
    from {
        padding-top: 100px;
        color: transparent;
    }
    to {
        padding-top: 0;
        color: black;
    }
}

/* Standard syntax */
@keyframes intro {
    from {
        padding-top: 100px;
        color: transparent;
    }
    to {
        padding-top: 0;
        color: blacks;
    }
}

.offerNavbarBody:hover {
    color: black !important;
}

.offerNavbarBody:nth-of-type(3)::before {
    content: 'BIEGANIE';
    font-size: 1.7em;
    top: 40%;
    left: 10%;
    position: relative;
}

.offerNavbarBody:nth-of-type(5)::before {
    content: 'KOLARSTWO';
    font-size: 1.7em;
    top: 40%;
    left: 10%;
    position: relative;

}

.offerNavbarBody:nth-of-type(5) {
    margin-left: -5em;
    z-index: 2;
    position: relative;
    /*background: #59a9e0;*/
    background: rgb(30,87,153);
    background: -moz-linear-gradient(45deg,  rgba(30,87,153,1) 0%, rgba(41,137,216,1) 52%, rgba(125,185,232,1) 100%);
    background: -webkit-linear-gradient(45deg,  rgba(30,87,153,1) 0%,rgba(41,137,216,1) 52%,rgba(125,185,232,1) 100%);
    background: linear-gradient(45deg,  rgba(30,87,153,1) 0%,rgba(41,137,216,1) 52%,rgba(125,185,232,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=1 );

}

.arrowRight:nth-of-type(4) {
    border-left: 5em solid #765eb2;
}

.offerNavbar {
    height: 10em;
    width: 100%;
    color: #FFF;
    font-weight: bold;
    font-size: larger;
    text-align: center;
    padding: 0;
    margin: 0;
}

.offerButton {
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    top: -2.5em;
    position: relative;
}

.offerButton:focus {
    outline: none;
}


@media (max-width: 900px)
{
    .offerNavbar {
        height: 21em;
    }

    .offerNavbarBody:nth-of-type(1)::before {
        font-size: 1.2em;
    }

    .offerNavbarBody {
        width: 100% !important;
        height: 7em;
        display: block;
    }

    .offerNavbarBody:last-child {
        clear: both;
    }

    .offerNavbarBody:nth-of-type(3) {
        margin-left: 0;
    }

    .offerNavbarBody:nth-of-type(5) {
        margin-left: 0;
    }

    .offerNavbarBody:nth-of-type(3)::before {
        font-size: 1.2em;
        left: 0;
    }
    .offerNavbarBody:nth-of-type(5)::before {
        font-size: 1.2em;
        left: 0;
    }

    .arrowRight {
        display: none;
    }
}