#about_us {
    height: auto;
    background: rgb(192,0,122);
    background: -moz-radial-gradient(to bottom right, ellipse cover,  rgba(192,0,122,1) 0%, rgba(8,1,24,1) 70%);
    background: -webkit-radial-gradient(to bottom right, ellipse cover,  rgba(192,0,122,1) 0%,rgba(8,1,24,1) 70%);
    background: radial-gradient(ellipse at bottom right,  rgba(192,0,122,1) 0%,rgba(8,1,24,1) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0007a', endColorstr='#080118',GradientType=1 );
    color: #FFF;
}

.background_sign {
    background: url('/img/background_sign.png');
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: 70% 10%;
    height: 100%;
}

.background_sign_bottom {
    background: url('/img/background_sign.png');
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: -30% 270px;
}

.background_sign p {
    font-weight: lighter;
    text-align: justify;
}

.background_sign p b {
    font-weight: bold;
}

.background_sign .coloredList li:last-child {
    margin-top: 7em;
}

.background_sign .fullButton:last-child {
    margin-top: 6.5em;
    text-align: center;
}

.background_sign .coloredList li:last-child::before,
.background_sign .fullButton:last-child::before {
    height: 7.1em;
    top: -7.2em;
}

.background_sign .coloredList li:first-child::before {
    display: none;
}

.background_sign .coloredList .full {
    background: #ea06a5;
}

@media all {
    .background_sign li {
        height: 2em;
        line-height: 1em;
        margin-top: 4em;
        display: block;
        position: relative;
        text-align: justify;
    }

    .background_sign li:first-child {
        margin-top: 0;
    }

    .background_sign .coloredList li {
        width: 2em;
        border-radius: 1em;
        line-height: 2em;
        border: 2px solid #ea06a5;
        color: white;
        text-align: center;
    }

    .background_sign .coloredList li::before {
        content: '';
        position: absolute;
        top: -4.2em;
        left: .7em;
        width: .2em;
        height: 4.1em;
        background: #ea06a5;
    }

    .nextTo li:nth-child(1) {
        position: absolute;
        top: .4em;
    }

    .nextTo li:nth-child(2) {
        position: absolute;
        top: 2.4em;
    }

    .nextTo li:nth-child(3) {
        position: absolute;
        top: 8.4em;
    }

    .nextTo li:nth-child(4) {
        position: absolute;
        top: 14.4em;
    }

    .nextTo li:nth-child(5) {
        position: absolute;
        top: 20.4em;
        height: auto;
    }

    .nextTo li:nth-child(6) {
        position: absolute;
        bottom: 2em;
        left: 50%;
        transform: translateX(-50%);
    }

    .nextTo li {
        margin-left: 1em;
    }
}

@media (min-width: 768px) {

    .nextTo li {
        margin-left: -2em;
    }

    .nextTo li:nth-child(1) {
        position: absolute;
        top: .4em;
    }

    .nextTo li:nth-child(2) {
        position: absolute;
        top: 2.4em;
    }

    .nextTo li:nth-child(3) {
        position: absolute;
        top: 6.4em;
    }

    .nextTo li:nth-child(4) {
        position: absolute;
        top: 10.4em;
    }

    .nextTo li:nth-child(5) {
        position: absolute;
        top: 14.4em;
    }

    .nextTo li:nth-child(6) {
        position: absolute;
        top: 18.4em;
        left: 50%;
        transform: translateX(-50%);
    }

    .fullButton {
        top: -2em;
    }

    .background_sign li {
        height: auto;
        line-height: 1em;
        margin-top: 2em;
        display: block;
        position: relative;
        text-align: justify;
    }

    .background_sign .coloredList li {
        height: 2em;
        width: 2em;
        line-height: 2em;
        border-radius: 1em;
        border: 2px solid #ea06a5;
        color: white;
        text-align: center;
    }

    .background_sign .coloredList li::before {
        content: '';
        position: absolute;
        top: -2.2em;
        left: .7em;
        width: .2em;
        height: 2.1em;
        background: #ea06a5;
    }
}