#trainings {
    background-color: #080118;
    color: #FFF;
}

.trainingsTable {
    border: 0 !important;
    color: #FFF !important;
    text-align: center;
    text-transform: uppercase;
}

.trainingsTable .col-md-3,
.trainingsTable .col-6,
.trainingsTable .col-3 {
    border: 0 !important;
    color: #FFF !important;
}

.trainingsTable .text-body .row:nth-child(even) {
    background-color: #82085d;
}

.trainingsTable .text-body .row {
    border-radius: 20px !important;
}

.trainingsTable .row .col-md-3:last-child,
.trainingsTable .row .col-6:last-child,
.trainingsTable .row .col-3:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.trainingsTable .row .col-md-3,
.trainingsTable .row .col-6,
.trainingsTable .row .col-3 {
    padding: .75rem 0;
    margin-top: auto;
    margin-bottom: auto;
}

.trainingsTable .thead-light .row .col-md-3,
.trainingsTable .thead-light .row .col-6,
.trainingsTable .thead-light .row .col-3 {
    white-space: nowrap;
    color: #ea06a5 !important;
    font-weight: bold;
    margin-bottom: .2px;
}