#apply {
    height: auto;
    background: rgb(192,0,122);
    background: -moz-radial-gradient(to bottom right, ellipse cover,  rgba(192,0,122,1) 0%, rgba(8,1,24,1) 70%);
    background: -webkit-radial-gradient(to bottom right, ellipse cover,  rgba(192,0,122,1) 0%,rgba(8,1,24,1) 70%);
    background: radial-gradient(ellipse at bottom right,  rgba(192,0,122,1) 0%,rgba(8,1,24,1) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0007a', endColorstr='#080118',GradientType=1 );
    color: #FFF;
}

#apply h1::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 10px;
    margin-top: 50px;
    background: rgba(102,15,90,1);
    background: -moz-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(102,15,90,1)), color-stop(100%, rgba(160,22,130,1)));
    background: -webkit-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: -o-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: -ms-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: linear-gradient(to right, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#660f5a', endColorstr='#a01682', GradientType=1 );
}


#apply .coloredList li:first-child::before {
    display: none;
}

@media all {
    #apply li {
        height: 2em;
        line-height: 1em;
        margin-top: 4em;
        display: block;
        position: relative;
        text-align: justify;
    }

    #apply li:first-child {
        margin-top: 0;
    }

    #apply .coloredList li {
        width: 2em;
        border-radius: 1em;
        line-height: 2em;
        border: 2px solid #ea06a5;
        color: white;
        text-align: center;
    }

    #apply .coloredList li::before {
        content: '';
        position: absolute;
        top: -4.2em;
        left: .7em;
        width: .2em;
        height: 4.1em;
        background: #ea06a5;
    }

    .nextToApply li:nth-child(1) {
        position: absolute;
        top: .4em;
    }

    .nextToApply li:nth-child(2) {
        position: absolute;
        top: .4em;
    }

    .nextToApply li:nth-child(3) {
        position: absolute;
        top: .4em;
    }

    .nextToApply li {
        margin-left: 1em;
    }
}

@media (min-width: 768px) {

    #apply li {
        height: auto;
        line-height: 1em;
        margin-top: 2em;
        display: block;
        position: relative;
        text-align: justify;
    }

    #apply .coloredList li {
        height: 2em;
        width: 2em;
        line-height: 2em;
        border-radius: 1em;
        border: 2px solid #ea06a5;
        color: white;
        text-align: center;
    }

    #apply .coloredList li::before {
        content: '';
        position: absolute;
        top: -2.2em;
        left: .7em;
        width: .2em;
        height: 2.1em;
        background: #ea06a5;
    }

    .nextToApply li {
        margin-left: -2em;
    }

    .nextToApply li:nth-child(1) {
        position: absolute;
        top: .4em;
    }

    .nextToApply li:nth-child(2) {
        position: absolute;
        top: 1.4em;
    }

    .nextToApply li:nth-child(3) {
        position: absolute;
        top: 2.4em;
    }
}

.applyFormImage {
    width: 9em;
    height: auto;
}

#applyForm label {
    margin-left: 10px;
    margin-top: 1em;
    display: block;
}

#applyForm input[type=input],
#applyForm textarea {
    width: 75%;
    background: #74095c;
    border: #74095c;
    height: 40px;
    border-radius: 20px;
    color: #FFF;
    padding-left: 30px;
    padding-right: 30px;
    text-transform: uppercase;
}

#applyForm textarea {
    height: auto;
    text-transform: none;
}

#applyForm input[type=checkbox] {
    margin-right: 10px;
}

#applyForm .rodoLabel:hover {
    cursor: pointer;
}

#applyForm input[type=checkbox]:hover {
    cursor: pointer;
}

#applyForm input:focus,
#applyForm textarea:focus {
    outline: none;
}

.invalid {
    border: 2px solid #ff253a !important;
    color: #ff253a !important;
}

#applyForm small {
    color: #ff253a;
    display: block;
    margin-left: 1em;
    margin-top: .5em;
}

.invalidText {
    color: #ff253a;
}

.rodoLabel {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: .7em;
}

/* Hide the browser's default checkbox */
.rodoLabel input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 3rem;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #74095c;
    border-radius: 20px;
}

/* On mouse-over, add a grey background color */
.rodoLabel:hover input ~ .checkmark {
    background-color: #4c033c;
}

/* When the checkbox is checked, add a blue background */
.rodoLabel input:checked ~ .checkmark {
    background-color: #74095c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.rodoLabel input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.rodoLabel .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}