.navbar {
    background-color: #0a011c;
    color: white;
    max-width: 100vw !important;
    overflow-y: hidden;
}

.navLink {
    color: white !important;
    height: 100%;
}

.navLink:hover {
    background: rgba(183,0,122,1);
    background: -moz-linear-gradient(left, rgba(183,0,122,1) 0%, rgba(153,52,135,1) 29%, rgba(125,71,147,1) 57%, rgba(104,130,187,1) 78%, rgba(110,159,207,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(183,0,122,1)), color-stop(29%, rgba(153,52,135,1)), color-stop(57%, rgba(125,71,147,1)), color-stop(78%, rgba(104,130,187,1)), color-stop(100%, rgba(110,159,207,1)));
    background: -webkit-linear-gradient(left, rgba(183,0,122,1) 0%, rgba(153,52,135,1) 29%, rgba(125,71,147,1) 57%, rgba(104,130,187,1) 78%, rgba(110,159,207,1) 100%);
    background: -o-linear-gradient(left, rgba(183,0,122,1) 0%, rgba(153,52,135,1) 29%, rgba(125,71,147,1) 57%, rgba(104,130,187,1) 78%, rgba(110,159,207,1) 100%);
    background: -ms-linear-gradient(left, rgba(183,0,122,1) 0%, rgba(153,52,135,1) 29%, rgba(125,71,147,1) 57%, rgba(104,130,187,1) 78%, rgba(110,159,207,1) 100%);
    background: linear-gradient(to right, rgba(183,0,122,1) 0%, rgba(153,52,135,1) 29%, rgba(125,71,147,1) 57%, rgba(104,130,187,1) 78%, rgba(110,159,207,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b7007a', endColorstr='#6e9fcf', GradientType=1 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all .2s ease-in;
}

.navSocial {
    color: white !important;
    font-size: 1.5em !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.navSocial:hover {
    color: #b70079 !important;
    transition: color .2s linear;
}

.navbar-toggler:focus {
    outline: none;
}

@media all {
    .logo {
    width: 60vw;
    height: auto;
    }
}
@media (min-width: 768px) {
    .logo {
        width: 20vw;
        height: auto;
    }
}

.logo:hover {
    cursor: pointer;
}

.no-wrap {
    white-space: nowrap;
}