#footer {
    background: #080118 !important;
}

#footer h2::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 7px;
    margin-top: 50px;
    background: rgba(102,15,90,1);
    background: -moz-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(102,15,90,1)), color-stop(100%, rgba(160,22,130,1)));
    background: -webkit-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: -o-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: -ms-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: linear-gradient(to right, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#660f5a', endColorstr='#a01682', GradientType=1 );
}

#footer button {
    display: block;
    font-size: 1.2em;
    background: transparent;
    color: rgba(255, 255, 255, 0.8);
    border: none;
    font-weight: lighter;
}

#footer button:hover {
    color: rgba(102,15,90,1);
    cursor: pointer;
}

.footerButton {
    display: block;
    font-size: 1.2em;
    background: transparent;
    color: rgba(255, 255, 255, 0.8);
    border: none;
    font-weight: lighter;
}

.footerButton:hover {
    color: rgba(102,15,90,1);
    cursor: pointer;
    text-decoration: none;
}