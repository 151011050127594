.joinButton {
    color: #FFF !important;
    border: #c1148d 3px solid !important;
    border-radius: 20px !important;
    font-weight: bold;
    white-space: nowrap;
}

.joinButton:hover {
    background-color: #c1148d !important;
}

.joinButtonAnimate {
    animation: crescendo 1s alternate infinite ease-in;
}

@keyframes crescendo {
    0%   {transform: scale(.95);}
    100% {transform: scale(1.05);}
}