#team {
    background: #080118 !important;
}

#team h1::before {
    content: '';
    position: absolute;
    width: 70px;
    height: 10px;
    margin-top: 50px;
    background: rgba(102,15,90,1);
    background: -moz-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(102,15,90,1)), color-stop(100%, rgba(160,22,130,1)));
    background: -webkit-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: -o-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: -ms-linear-gradient(left, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    background: linear-gradient(to right, rgba(102,15,90,1) 0%, rgba(160,22,130,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#660f5a', endColorstr='#a01682', GradientType=1 );
}

.teamImage {
    background: url("/img/team.jpg") no-repeat;
    background-position: bottom;
    background-size: 100vw 39.6vw;
    height: 45.6vw;
    position: relative;
}

.waveTop {
    background: url("/img/wave.png");
    background-size: 120vw 8vw;
    background-repeat: no-repeat;
    background-position: bottom left;
    height: 8vw;
    color: #FFF;
}

.waveCenter {
    height: 29.7vw;
}

.waveBottom {
    background: url("/img/wave.png");
    background-size: 120vw 8vw;
    background-repeat: no-repeat;
    background-position: top left;
    height: 8vw;
    color: #FFF;
    transform: rotate(-180deg);
}

.teamJoinButton {
    text-align: center;
    margin-top: -2em;
}

.partners {
    background: #080118;
}

.partners img {
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {

    .partners {
        padding: 1em;
    }

    .partners img {
        width: 60%;
        height: auto;
    }
}